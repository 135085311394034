import { Injectable } from '@angular/core';
import { FormSection } from '../../../../shared/models/form.config';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';

@Injectable({ providedIn: 'root' })
export class AuditDetailsDraftReportReviewSubmitFormConfig {
  formSections: FormSection[] = [
    {
      key: 'audit_details_draft_report_review_submit',
      title: '',
      fields: [
        
        {
          id: 'reviewerId',
          label: 'Reviewer',
          formControlName: 'Reviewer',
          type: 'autocomplete-static',
          placeholder: 'Select the reviewer',
          size: 12,
          isInfoIcon: false,
          validators: ['required'],
        },
        
        {
          id: 'comment',
          label: 'Comment',
          formControlName: 'Comment',
          type: 'textarea',
          placeholder: 'Enter the comment',
          size: 12,
          validators: [],
        },
       
      ],
    },
  ];

  constructor(private userPreference: CurrentUserPreferenceStore) {}
 
  updateOptions(
    fields: FormSection[],
    users: any,
  ): void {
    const languageKey: string = this.userPreference.languageKey || 'en';

    fields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === 'reviewerId') {
          field.options = users?.map((user: any) => {
            return {
              id: user.id,
              title: `${user.first_name} ${user.last_name}`,
            }
          });
        }
      });
    });
  }
}
