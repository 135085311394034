import { Injectable } from '@angular/core';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import { UserMinimalDTO } from '../../../organization/users/dto/user.dto';
import { AuditDetailsActionPlanDetailsDTO, AuditDetailsActionPlanFormDTO, AuditDetailsActionPlanListDTO } from '../dto/audit-action-plan.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditDetailsActionPlanConversationConversion
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any): AuditDetailsActionPlanListDTO {
    return {
      // TODO: not currently needed
     };
  }
  formGroupToForm(formGroup: FormGroup): AuditDetailsActionPlanFormDTO {
    return {
      title: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      responsible_user_id: formGroup.get('ResponsibleUser')?.value,
      target_date: formGroup.get('TargetDate')?.value,
    };
  }
  resToForm(response: any): any {
    return {
      Title: response.title,
      Description: response.description,
      ResponsibleUser: response.responsible_user?.id,
      TargetDate: response.target_date,
    };
  }
  resToDetails(response: any): AuditDetailsActionPlanDetailsDTO {
    return {
      id: response.id,
      title: response.title,
      description: response.description,
      responsible_user: this.userConv.resObjToUserMinimalDTO(response.responsible_user),
      target_date: response.target_date,
    };
  }


  formGroupToDetails(formGroup: FormGroup, id:number, currentUser: UserMinimalDTO): AuditDetailsActionPlanDetailsDTO {
    return {
      id: id,
      title: formGroup.get('Type')?.value,
      description: formGroup.get('Description')?.value,
      responsible_user: this.userConv.resObjToUserMinimalDTO(currentUser),
      target_date: formGroup.get('TargetDate')?.value,
    
    };
  }
}
