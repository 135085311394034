import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { AuditChecklistListDTO } from '../dto/audit-checklist.dto';
import { MasterConversion } from '../../../../shared/conversion/master.conversion';
import { FormGroup } from '@angular/forms';
import {
  AuditChecklistFindingDetailsDTO,
  AuditChecklistFindingDTO,
  AuditChecklistFindingFormDTO,
} from '../dto/audit-checklist-finding.dto';
import { FileConversion } from '../../../../shared/conversion/file.converstion.dto';
import { getAuditableItemTypeTitleDetails } from '../../audit-plan/conversion/audit-plan-details-auditable-item.conversion';
import { AuditDetailsDraftFindingConversationConversion } from './audit-details-draft-finding-conversation.conversion';
import { AuditDetailsActionPlanConversationConversion } from './audit-details-action-plan.conversion';

@Injectable({
  providedIn: 'root',
})
export class AuditChecklistFindingConversion {
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion,
    private fileConv: FileConversion,
    private masterConv: MasterConversion,
    private auditDetailsDraftFindingConversationConv: AuditDetailsDraftFindingConversationConversion,
    private auditDetailsActionPlanConversationConv: AuditDetailsActionPlanConversationConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: AuditChecklistListDTO[], lang: string): any[] {
    return [];
  }

  formGroupToForm(
    formGroup: FormGroup,
    auditId: number,
    auditableItemId: number,
    checklistId: number
  ): AuditChecklistFindingFormDTO {
    return {
      id: formGroup.get('id')?.value,
      audit_id: auditId,
      auditable_item_id: auditableItemId,
      audit_checklist_id: checklistId,
      title: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      audit_finding_category_id: formGroup.get('FindingCategory')?.value,
      severity_level_id: formGroup.get('SeverityLevel')?.value,
      recommendations: formGroup.get('Recommentations')?.value,
      documents: formGroup.get('Evidence')?.value,
    };
  }

  resToForm(response: any): any {
    return {
      id: response.id,
      Title: response.title,
      Description: response.description,
      FindingCategory: response.audit_finding_category_id,
      SeverityLevel: response.severity_level_id,
      Recommentations: response.recommendations,
      Evidence: response.documents,
    };
  }


  resToDetails(
    response: any,
    openFinding?: AuditChecklistFindingDetailsDTO
  ): AuditChecklistFindingDetailsDTO {
   const { title, type } = getAuditableItemTypeTitleDetails(response.auditable_item, this.lang);
   const reversedConversations = response.conversations ? [...response.conversations].reverse() : [];

    return {
      id: response.id,
      is_open: openFinding ? openFinding.id === response.id : false,
      audit_id: response.audit_id,
      title: response.title,
      description: response.description,
      recommendations: response.recommendations,
      audit_checklist: response.audit_checklist,
      audit_draft_finding_status: this.masterConv.resToDetails(
        response.audit_draft_finding_status
      ),
      created_at: response.created_at,
      updated_at: response.updated_at,
      documents: this.fileConv.resToFileDetailsDTOArray(response.documents),
      audit_finding_category: this.masterConv.resToDetails(
        response.audit_finding_category
      ),
      severity_level: this.masterConv.resToDetails(response.severity_level),
      created_user: this.userConv.resObjToUserMinimalDTO(response.created_by_id),
      updated_user: this.userConv.resObjToUserMinimalDTO(response.updated_by_id),
      auidtable_item_title: title,
      latest_response: response.conversations?.length
      ? this.auditDetailsDraftFindingConversationConv.resToDetails(
          response.conversations.reduce((prev: any, current: any) => {
            return prev.id > current.id ? prev : current;
          })
        )
      : null,
      conversations: reversedConversations?.map((coversation:any) => this.auditDetailsDraftFindingConversationConv.resToDetails(coversation)),
      action_plans: response.action_plans?.map((actionPlan:any) => this.auditDetailsActionPlanConversationConv.resToDetails(actionPlan))
    };
  }
}
