import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { FormSection } from '../../../../shared/models/form.config';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { AlertService } from '../../../../core/services/alert.service';
import { UiStore } from '../../../../core/store/ui.store';
import { AuditDetailsStore } from './audit-details.store';
import { AuditDraftReportService } from '../services/audit-draft-report.service';
import { AuditDetailsDraftReportConversion } from '../conversion/audit-details-draft-report.conversion';
import {
  AuditDraftReportDetailsDTO,
  AuditDraftReportDocumentDTO,
} from '../dto/audit-draft-report.dto';
import { AuditDetailsDraftReportReviewSubmitFormConfig } from '../config/audit-details-draft-report-review-submit-form.config';
import { AuditDraftReportReviewSubmitFormDTO } from '../dto/audit-draft-report-review-submit.dto';
import { AuditDetailsDraftReportReviewSubmitConversion } from '../conversion/audit-details-draft-report-review-submit.conversion';
import { FormGroup } from '@angular/forms';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';

@Injectable({ providedIn: 'root' })
export class AuditDetailsDraftReportStore {
  //Common
  auditId: number;
  entityName: string = '';

  //Detials
  isInitialLoading: boolean = true;
  isLoading: boolean = false;
  auditDraftReportDocument: AuditDraftReportDocumentDTO;
  auditDraftReportDetails: AuditDraftReportDetailsDTO;

  isDeleting: boolean = false;

  //Submit for Internal Review
  formGroup = new FormGroup({});
  fields: FormSection[] = [];
  isSideReviewSubmitFormOpen: boolean = false;
  submitButtonTitle: string = '';
  reportSubmitType: string = ''; // Set Report type submit or Resubmit

  constructor(
    protected confirmService: ConfirmService,
    protected alertService: AlertService,
    protected uiStore: UiStore,
    public auditDetailsStore: AuditDetailsStore,
    public auditDraftReportService: AuditDraftReportService,
    public auditDetailsDraftReportConversion: AuditDetailsDraftReportConversion,
    private auditDetailsDraftReportReviewSubmitConversion: AuditDetailsDraftReportReviewSubmitConversion,
    public auditDetailsDraftReportReviewSubmitFormConfig: AuditDetailsDraftReportReviewSubmitFormConfig,
    public currentUserPreferenceStore: CurrentUserPreferenceStore
  ) {
    makeObservable(this, {
      fields: observable,
      isSideReviewSubmitFormOpen: observable,
      updateFormOptions: action,
      loadDraftReport: action,
    });
  }

  initialize(): void {
    this.entityName = 'Audit Draft Report';
    this.auditId = this.auditDetailsStore.details.id; // Ensure auditDetailsStore is populated before calling this
    this.auditDraftReportDocument = {} as AuditDraftReportDocumentDTO;
    this.auditDraftReportDetails = {} as AuditDraftReportDetailsDTO;
    this.fields =
      this.auditDetailsDraftReportReviewSubmitFormConfig.formSections;
    this.isInitialLoading = true; // Assuming that the loading will start soon after init
    this.isLoading = false;
    this.submitButtonTitle = 'Submit';
  }

  loadDraftReport(): void {
    if (!this.auditId) {
      console.error('main id is missing');
      return;
    }

    this.isLoading = true;
    this.auditDraftReportService.get(this.auditId).subscribe({
      next: (response: any) => {
        runInAction(() => {
          if (response) {
            this.auditDraftReportDetails =
              this.auditDetailsDraftReportConversion.resToDetails(response);
            this.auditDraftReportDocument =
              this.auditDetailsDraftReportConversion.resToDraftReportDocument(
                response
              );
          }
          this.isInitialLoading = false;
          this.isLoading = false;
        });
      },
      error: (error: any) => {
        console.error('Failed to load data:', error);
        this.isLoading = false;
      },
    });
  }

  get draftReportStatusType() {
    return this.auditDraftReportDetails?.draft_report_status?.type;
  }

  //Start Draft Report Status Check
  isDraft() {
    return this.draftReportStatusType === 'draft';
  }

  isInternalReview() {
    return this.draftReportStatusType === 'in-review';
  }

  isReverted() {
    return this.draftReportStatusType === 'revert';
  }

  isDepartmentReview() {
    return this.draftReportStatusType === 'in-department-review';
  }

  isCompleted() {
    return this.draftReportStatusType === 'completed';
  }

  isApproved() {
    return this.draftReportStatusType === 'approved';
  }

  isReviewUser(){
    const currentUserId = this.currentUserPreferenceStore.user.id;
    return this.auditDraftReportDetails.review_users.some(user => user.id === currentUserId);
  }

  deleteReport(): void {
    //allow to delete only draft report in draft statu sonly

    // Check if mainId is defined
    if (!this.auditId) {
      console.error('Main ID is not defined');
      throw new Error('Main ID is required');
    }

    this.confirmService.confirmDeleteItem().then((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.isDeleting = true;

        // Ensure ids is always an array
        const ids = [this.auditDraftReportDocument.id];
        if (this.auditId) {
          //remove ids, just pass audit id to delete draft report. and change method according to api delete.
          this.auditDraftReportService.delete(this.auditId, ids).subscribe({
            next: () => {
              this.alertService.success('Deleted!', 'Success');
              runInAction(() => {
                this.isDeleting = false;
                this.auditDraftReportDetails = {} as AuditDraftReportDetailsDTO;
                this.auditDraftReportDocument =
                  {} as AuditDraftReportDocumentDTO;
              });
            },
            error: (error: any) => {
              this.isDeleting = false;
              this.alertService.error('Error while deleting!', 'Error!');
              console.error('Error while deleting', error);
            },
          });
        }
      }
    });
  }
  //End Draft Report Status Check

  //Start Review Form
  openReviewSubmitForm(type: string): void {
    runInAction(() => {
      if(type == 're-submit'){
        this.submitButtonTitle = 'Re Submit';
      }
      this.reportSubmitType = type
      this.isSideReviewSubmitFormOpen = true;
      this.updateFormOptions();
    });
  }

  closeReviewSubmitForm(): void {
    this.isSideReviewSubmitFormOpen = false;
  }

  convertFormToDTO(): AuditDraftReportReviewSubmitFormDTO {
    return this.auditDetailsDraftReportReviewSubmitConversion.formGroupToForm(
      this.formGroup
    );
  }

  reviewSubmitReport(): void {
    if (!this.auditDraftReportDocument || !this.auditDraftReportDocument.id) {
      console.error('Draft report must be generated with a valid ID');
      throw new Error('Draft report must be generated with a valid ID');
    }
  
    // Check if mainId is defined
    if (!this.auditId) {
      console.error('Main ID is not defined');
      throw new Error('Main ID is required');
    }
  
    this.uiStore.startSaving();
  
    // Ensure the form is valid
    if (!this.formGroup.valid) {
      this.uiStore.finishSaving();
      return;
    }
  
    const formData = this.convertFormToDTO();
    const id = this.auditDraftReportDocument.id;
  
    let submitObservable;
  
    if (this.reportSubmitType == 'submit') {
      // Handle submit logic
      submitObservable = this.auditDraftReportService.reviewSubmit(this.auditId, id, formData);
    } else if (this.reportSubmitType == 're-submit') {
      // Handle re-submit logic
      submitObservable = this.auditDraftReportService.reviewReSubmit(this.auditId, id, formData);
    } else {
      console.error('Unknown submit type');
      this.uiStore.finishSaving();
      return;
    }
  
    // Now subscribe to the submit or re-submit observable
    submitObservable.subscribe({
      next: () => {
        this.closeReviewSubmitForm();
        this.isLoading = false;
        this.alertService.success('Submitted!', 'Success');
        this.uiStore.finishSaving();
        this.loadDraftReport();
      },
      error: (error: any) => {
        this.isLoading = false;
        this.uiStore.finishSaving();
        this.alertService.error('Error while submitting!', 'Error!');
        console.error('Error while submitting', error);
      },
    });
  }
  
  updateFormOptions() {
    this.auditDetailsDraftReportReviewSubmitFormConfig.updateOptions(
      this.fields,
      this.auditDetailsStore.auditMembers
    );
  }

  //END Review Submit Form
  resetEntireState(): void {
    runInAction(() => {
      this.reportSubmitType = '';
      this.submitButtonTitle = '';
      this.entityName = '';
      this.fields = [];
      this.auditDraftReportDocument = {} as AuditDraftReportDocumentDTO;
      this.auditDraftReportDetails = {} as AuditDraftReportDetailsDTO;
      this.formGroup.reset(); // Reset the form
      this.isSideReviewSubmitFormOpen = false; // Reset the review form state as well
      this.isLoading = false;
      this.isInitialLoading = false;
      this.isDeleting = false;
    });
  }
}
