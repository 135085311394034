import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { AuditDraftReportFormDTO } from '../dto/audit-draft-report.dto';
import { AuditDraftReportReviewHistoryDTO, AuditDraftReportReviewSubmitFormDTO } from '../dto/audit-draft-report-review-submit.dto';
import { AuditDraftReportCommentFormDTO } from '../dto/audit-draft-report-comment.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditDraftReportService {
  protected apiUrl = environment.apiUrl + '/audits';

  constructor(protected http: HttpClient) {}

  get(auditId: number): Observable<any> {
    const url = `${this.apiUrl}/${auditId}/draft-reports`;
    return this.http.get<any>(url);
  }

  create(
    auditId: number,
    item: AuditDraftReportFormDTO
  ): Observable<AuditDraftReportFormDTO> {
    return this.http.post<AuditDraftReportFormDTO>(
      `${this.apiUrl}/${auditId}/draft-reports`,
      item
    );
  }

  update(
    auditId: number,
    itemId: number,
    item: AuditDraftReportFormDTO
  ): Observable<AuditDraftReportFormDTO> {
    return this.http.put<AuditDraftReportFormDTO>(
      `${this.apiUrl}/${auditId}/draft-reports/${itemId}`,
      item
    );
  }

  delete(auditId: number, ids: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/${auditId}/draft-reports/delete`, {
      ids,
    });
  }

  reviewSubmit(
    auditId: number,
    id: number,
    item: AuditDraftReportReviewSubmitFormDTO
  ): Observable<AuditDraftReportReviewSubmitFormDTO> {
    return this.http.post<AuditDraftReportReviewSubmitFormDTO>(
      `${this.apiUrl}/${auditId}/draft-reports/${id}/submit`,
      item
    );
  }

  reviewReSubmit(
    auditId: number,
    id: number,
    item: AuditDraftReportReviewSubmitFormDTO
  ): Observable<AuditDraftReportReviewSubmitFormDTO> {
    return this.http.post<AuditDraftReportReviewSubmitFormDTO>(
      `${this.apiUrl}/${auditId}/draft-reports/${id}/re-submit`,
      item
    );
  }


  getCommentList(
    auditId: number,
    reportId: number,
    type: string,
    sortField: string = '',
    sortOrder: string = 'desc',
    dynamicFilters?: { [key: string]: number[] },
  ): Observable<any> {
    let params = new HttpParams()
    .set('sort_by', sortField)
    .set('sort_order', sortOrder);

    if (dynamicFilters) {
      Object.keys(dynamicFilters).forEach((key) => {
        const values = dynamicFilters[key];
        if (values && values.length > 0) {
          params = params.set(key, values.join(','));
        }
      });
    }

    return this.http.get<any>(
      `${this.apiUrl}/${auditId}/draft-reports/${reportId}/comments?type=${type}`,
      { params }
    );
  }

  saveComment(
    auditId: number,
    reportId: number,
    item: AuditDraftReportCommentFormDTO
  ): Observable<AuditDraftReportCommentFormDTO> {
    return this.http.post<AuditDraftReportCommentFormDTO>(
      `${this.apiUrl}/${auditId}/draft-reports/${reportId}/comments`,
      item
    );
  }

  getCommentDownloadURL(auditId: number, reportId: number, commentId: number, token: string): string {
    return `${this.apiUrl}/${auditId}/draft-reports/${reportId}/comments/${commentId}/document-download?token=${token}`;
  }

  updateCommentStatus(
    auditId: number,
    reportId: number,
    commentId: number,
    resolveStatus: boolean
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/${auditId}/draft-reports/${reportId}/comments/${commentId}/status-update`,
      {is_resolved: resolveStatus}
    );
  }
  
  approveReport(
    auditId: number,
    reportId: number,
    comment: string
  ): Observable<string> {
    return this.http.post<string>(
      `${this.apiUrl}/${auditId}/draft-reports/${reportId}/approve`,
      {comment}
    );
  }

  revertReport(
    auditId: number,
    reportId: number,
    comment: string
  ): Observable<string> {
    return this.http.post<string>(
      `${this.apiUrl}/${auditId}/draft-reports/${reportId}/revert`,
      {comment}
    );
  }

  getReviewHistory(
    auditId: number,
    id: number,
    sortField: string = '',
    sortOrder: string = 'desc',
  ): Observable<AuditDraftReportReviewHistoryDTO> {
    let params = new HttpParams()
    .set('sort_by', sortField)
    .set('sort_order', sortOrder);

    return this.http.get<AuditDraftReportReviewHistoryDTO>(
      `${this.apiUrl}/${auditId}/draft-reports/${id}/review-history`, 
      { params }
    );
  }
}
