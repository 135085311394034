import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import { AuditDraftReportReviewHistoryDTO, AuditDraftReportReviewSubmitFormDTO } from '../dto/audit-draft-report-review-submit.dto';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { TableConversion } from '../../../../shared/conversion/table.conversion';

@Injectable({
  providedIn: 'root',
})
export class AuditDetailsDraftReportReviewSubmitConversion
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
    private tableConv: TableConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }


  formGroupToForm(formGroup: FormGroup): AuditDraftReportReviewSubmitFormDTO {
    return {
      user_ids: [formGroup.get('Reviewer')?.value],
      comment: formGroup.get('Comment')?.value,
    };
  }
  

  resToAuditDraftReportReviewHistory(response: any[]): AuditDraftReportReviewHistoryDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        audit_draft_report_id: item.audit_draft_report_id,
        workflow_status: this.tableConv.resToColordItem(item, 'workflow_status'),
        user: this.userConv.resToUserMinimalIdFixedDTO(item, 'created_user'),
        comment: item.comment,
        commented_at: item.created_at,
        created_user: this.userConv.resToUserMinimalIdFixedDTO(item, 'created_user'),
      
      };
    });
    return result;
  }

  
}
