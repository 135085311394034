import { Injectable } from '@angular/core';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import { AuditDraftFindingConversationDetailsDTO, AuditDraftFindingConversationFormDTO, AuditDraftFindingConversationListDTO } from '../dto/audit-draft-finding-conversation.dto';
import { UserMinimalDTO } from '../../../organization/users/dto/user.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditDetailsDraftFindingConversationConversion
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }


  resToDetails(response: any): AuditDraftFindingConversationDetailsDTO {
    return {
      id: response.id,
      type: response.type,
      comment: response.description,
      commented_user: this.userConv.resObjToUserMinimalDTO(response.created_by_user),
      commented_at: response.created_at,
      documents :[]
    };
  }

}
